body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

canvas {
  width: 100% !important;
  height: 90vh !important;
}

.animation-item:hover {
  background-color: #304ffe !important;
  cursor: pointer;
}

.preloader-box {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(204, 196, 196, 0.8);
  display: flex;
  z-index: 999999;
  pointer-events: none;
}

.preloader-box .preloader-wrapper {
  width: 400px;
  height: 400px;
  margin: auto;
}

.circle-clipper .circle {
  border-width: 98px;
}
